@import "./scss/style.scss";

html,
body,
#root {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

.text-muted {
    opacity: 0.8;
}

.link-button {
    color: #1677ff;
    cursor: pointer;
}

.buttons {
    white-space: pre;
    .ant-btn {
        margin-left: 5pt;
    }
}

.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-modal-mask {
    z-index: 9998 !important;
}

.ant-modal-wrap {
    z-index: 9999 !important;
}

.ant-modal-content {
    overflow: hidden;

    background-color: rgba(255, 255, 255);

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 13px;

        .ant-btn {
            margin-left: 13px;
        }
    }
}

.no-select {
    user-select: none;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    justify-content: center;
}

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 600;
}

code {
    font-family: inherit;
    font-size: inherit;
}

img[alt="photo"] {
    border: 1px solid lightblue;
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    margin-right: 21px;
    margin-bottom: 21px;
}

.grecaptcha-badge {
    visibility: hidden;
}
